import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Beschwerden = [
    "Depressionen, Burnout-Erkrankungen",
    "Akute Krisen, z.B. Lebenskrisen, Trennungen, Verlust, Krankheit",
    "Ängste und Phobien, z.B. beim Auftreten von Panikattacken, sozialen Ängsten, Ängsten vor Krankheiten / Tieren / Höhen, Angst vor Erbrechen (Emetophobie)",
    "Zwangserkrankungen",
    "(komplexe) Posttraumtische Belastungsstörungen und andere Traumafolgestörungen (nach Gewalt, Unfällen, Übergriffen oder Missbrauch)",
    "Psychosomatische Erkrankungen",
    "Chronische Schmerzen",
    "Sucht / Abhängigkeit",
    "Trauer und Abschied",
    "Belastungen in Partnerschaft und Sexualität: Eine Einzeltherapie, die zunächst auf die Auseinandersetzung mit den eigenen Sichtweisen fokussiert, kann bei Bedarf durch Paargespräche ergänzt werden. Alternativ können von Beginn an paartherapeutische Angebote wahrgenommen werden",
    "Schlafstörungen",
    "Probleme im Selbstwerterleben",
    "Zwischenmenschliche Schwierigkeiten",
]

const SpektrumPage = () => (
  <Layout>
    <Seo
        title="Behandlungsspektrum | Psychotherapie München | Verhaltentherapie"
        description="Therapie von Depressionen, Ängsten, Burnout, Zwängen & anderen psychischen Beschwerden. Mein Spektrum umfasst Psychotherapie, Paarberatung, Coaching und Prävention."
    />

    <div className="relative">
        <div className="w-full h-96">
            <StaticImage className="w-full h-full" src="../assets/images/behandlungsspektrum.jpg" alt="" />
            <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
                <div className="w-full mx-auto text-center text-white max-w-prose">
                    <h1 className="mb-4 lg:text-4xl">Behandlungs&shy;spektrum</h1>
                    <div className="px-4 sm:text-lg">In einem ausführlichen Erstgespräch klären wir gemeinsam, ob bei Ihnen Symptome im Sinne einer psychischen Erkrankung vorliegen und wie diese eingeordnet werden können.</div>
                </div>
            </div>
        </div>
    </div>
    <div className="my-12 boxed max-w-prose">
        <h2 className="mb-4">Häufige Beschwerden sind:</h2>
        <ul className="list-disc">
            {Beschwerden.map((Beschwerde) => (
                 <li key={Beschwerde} className="">{Beschwerde}</li>
            ))}
        </ul>
    </div>
  </Layout>
)

export default SpektrumPage
